import React, { useEffect, useState } from "react";
import './App.css';

function App() {

  const [message, setMessage] = useState("");

  useEffect(() => {

    const socket = new WebSocket("wss://21e0-80-115-231-121.ngrok-free.app");

    socket.onopen = () => {
      console.log("WebSocket connected");
    };

    socket.onmessage = (event) => {
      setMessage(event.data);
      console.log(event.data);
    };

    socket.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      socket.close();
    };
  }, []);



  return (
    <div className="App">

      {message < 100 && <header className="full bg-green-700">
        <p className="text-yellow-400 text-xl">
          In the stillness of the ether, <a className="text-white" href="https://twitter.com/naklecha" target="_blank">naklecha</a> sits enshrouded upon his desk, a figure cloaked in the veil of focus, entranced in the sorcery of his labor. His mind, a cauldron of arcane incantations, brews forth a brew of enigmatic musings, as he weaves his spells with the flicker of his fingers upon the keyboard. The shimmering glow of his monitor illuminates his countenance, casting his visage in an otherworldly light, as he delves deeper into the mystic realm of his work. A spectral presence hovers about him, drawn to the hypnotic rhythm of his keystrokes, whispering secrets and ancient knowledge into his ear, as he conjures forth the ethereal fruits of his labor.
          <br></br><br></br>
          <span>TLDR; <a className="text-white" href="https://twitter.com/naklecha" target="_blank">naklecha</a> is currently sitting on his desk.</span>
          <br></br><br></br>
          <span className="bg-white text-red-500 p-2">DISTANCE FROM ULTRASONIC SENSOR (in cm): {message}</span>
          <br></br><br></br>
          <span className="text-white">Say hi to WALL-E the ultrasonic sensor that is monitoring me.</span>
          <video src="demo.mp4" autoPlay={true} muted={true} controls={true}></video>
          <br></br>
          <a href="https://discord.gg/khjDqqu5Tj" target="_blank" className="bg-pink-400 p-1 px-2 rounded-md text-yellow-300">JOIN SECRET NOTPINK DISCORD SERVER</a>
        </p>
      </header>}
      {message >= 100 && <header className="full bg-red-700">
        <p className="text-yellow-400 text-xl">
          Amidst the mystic aura of the cosmos and the enigmatic whispers of the unseen, a revelation echoes forth: <a className="text-white" href="https://twitter.com/naklecha" target="_blank">naklecha</a>, the diligent and devoted, is not to be found sitting upon his desk, toiling away with his worldly duties. Nay, he has vanished into the shadows of the unknown, leaving behind mere echoes of his presence as testament to his absence. The very fabric of reality quivers with the weight of this enigma, beckoning forth the inquisitive to unravel the secrets that lay shrouded in the haze of the unexplained.

          <br></br><br></br>
          <span>TLDR; <a className="text-white" href="https://twitter.com/naklecha" target="_blank">naklecha</a> is not sitting on his desk.</span>
          <br></br><br></br>
          <span className="bg-white text-red-500 p-2">DISTANCE FROM ULTRASONIC SENSOR (in cm): {message}</span>
          <br></br><br></br>
          <span className="text-white">Say hi to WALL-E the ultrasonic sensor that is monitoring me.</span>
          <video src="demo.mp4" autoPlay={true} muted={true} controls={true}></video>
          <br></br>
          <a href="https://discord.gg/khjDqqu5Tj" target="_blank" className="bg-blue-400 p-1 px-2 rounded-md text-yellow-300">JOIN SECRET NOTPINK DISCORD SERVER</a>

        </p>
      </header>}

    </div>
  );
}

export default App;
